import React from 'react';

import spotify from "../assets/logo/spotify.png"
import deezer from "../assets/logo/deezer-logo-coeur.png"
import apple from "../assets/logo/apple.png"
import amazon from "../assets/logo/amazon_music.png"
import youtube from "../assets/logo/ytb.png"

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footerDiv">
        Copyright 2023 &copy;  Designed & Developed by&nbsp;<a href="https://www.maissadev.com" target="_blank" className='maissa-dev'>Maissa Dev</a>
      </div>
      <div className='footer-right'>
        <a href="https://open.spotify.com/intl-fr/artist/75Xu6l5hFNMoVPPaVZ9ga1?si=Jw6ML6qCQqCjkKGDzytONA" target="_blank"><img src={spotify} className="logo-nb"/></a>
        <a href="https://music.apple.com/fr/artist/rido-bayonne/423286493" target="_blank"><img src={apple} className="logo-nb"/></a>
        <a href="https://deezer.page.link/DKpSwpwD5dPXmMp67" target="_blank"><img src={deezer} className="logo-nb"/></a>
        <a href="https://www.youtube.com/channel/UCmn4T5ejhSre6UZa1dFy1fw" target="_blank"><img src={youtube} className="logo-nb"/></a>
      </div>
    </footer>
  );
}
