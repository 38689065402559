import React from 'react';
import alliances from "../../assets/img/alliances-3.jpg";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


export const BioEn = () => {

    return (
        <div>
            <div className="albums-all-container">
                <div className='banner-titre'>
                    <h1> ENGLISH BIOGRAPHY </h1>
                    <h2> Alliances sources of my inspiration </h2>
                </div>


                <div className='bio-button'>
                <Link to="/biographie">
  <Button variant="outline-dark"> Version française 🇫🇷 </Button>
</Link>

                </div>
                <div className='bio-container'>

                    <h3>Rido Bayonne – the Story of a Music Pioneer </h3>

                    <img src={alliances} />
                    <p className="bio-text"  ><span lang="en-US"><strong>Rido Dieudonné Bayonne</strong> (Bayonne is his actual name, handed down from father to son over three centuries) was born in 1947 in the Congo, heir to the boundless wealth of African sounds and rhythms.&nbsp;From childhood he’s beating time on tree trunks and boxes, dreaming of playing the guitar. His idols are <strong>Serge Essous</strong>, <strong>Nino Malapet</strong> and <strong>Joseph Kabasele</strong>.&nbsp;At the age of 13, he’s discovered by «&nbsp;Les Bantous de la Capitale&nbsp;» Orchestra and becomes the most popular drummer of the Congo. Then he lives 10 years in Cameroun with a foster family and learns to play the guitar, tutored by the father of modern congolese guitar,<strong> Tino Barosa.</strong> He plays in the clubs of the era like the Castel and the Saint-Hilaire.</span></p>
                    <p  >In Paris he perfects his talent and we soon find him appearing as bass guitarist alongside French performers and international stars such as <strong>Graeme Allright</strong> and <strong>Maxime Le Forestier</strong>.&nbsp;In 1972, Rido Bayonne forms the «&nbsp;Spheroe&nbsp;» band with <strong>Michel Perez</strong>, <strong>Gérard Maimone</strong> and <strong>Patrick Garel</strong>. They record two albums : «&nbsp;Spheroe&nbsp;» and «&nbsp;Primadonna&nbsp;». At the request of theater director <strong>Georges Lavaudant</strong>, they compose and perform the music for two plays : «&nbsp;Palazzo Mentale&nbsp;» and «&nbsp;King Lear&nbsp;».&nbsp;In 1978, he meets the famous American harmonist<strong> Sugar Blue</strong>. He becomes his bass guitarist and soon his composer, conductor and musical director. Together they compose and record «&nbsp;From Chicago to Paris&nbsp;» and perform all over Europe and the United States.</p>
                    <p lang="en-US"  >In 1979, he releases «&nbsp;Rido and James&nbsp;» and in 1982 «&nbsp;Eyano&nbsp;», two albums which establish his reputation as arranger, artistic director and all-round performer. He rubs shoulders with international stars such as <strong>James Brown</strong>, <strong>Dizzy Gillespie, Jaco Pastorius</strong> …</p>
                    <p ><span lang="en-US">To communicate his passion for daring and innovative composing, Rido Bayonne founds his Grand Orchestra in 1983. The challenge is great, but then so is his ambition. </span><span lang="en-US">On stage, this charismatic leader will merge the 18 musicians into a single breath, a single beating heart.&nbsp;With his Grand Orchestra, Rido Bayonne can finally bring it all together : Africa reaches out to Europe, the North meets the South, rumba from the Congo mates with makossa from Cameroun … with jazz, funk and classical music embracing them all.</span></p>
                    <p lang="en-US"  >Festivals and concerts follow, including the 1995 Bayonne Festival where he is invited as the City’s Guest of Honor. Rido Bayonne’s live concert «&nbsp;Gueule de Black&nbsp;», is recorded and produced by the «&nbsp;Scène Nationale de Bayonne&nbsp;» under the «&nbsp;Jazz aux Remparts&nbsp;» label.&nbsp;<span lang="en-US">Rido Bayonne then embarks on a monumental project : 5 years’ preparing and recording, coordinating 150 musicians, writing lyrics in 14 languages and weaving together a profusion of styles to bring forth his Hymn to Life and Universal Harmony. The album entitled «&nbsp;Hearts and Souls&nbsp;»</span> <span lang="en-US">is released in October 2001.</span></p>
                    <p lang="en-US"  >In 2002, he returns to his African homeland with his Grand Orchestra, on an extended musical tour hosted by the French Cultural Centers of the Congo and Cameroun. This tour will inspire the future album «&nbsp;Douala-Brazza&nbsp;».&nbsp;In 2004, he’s back in Africa at the «&nbsp;Jazz à Ouaga Festival&nbsp;» (Ouagadougou, Burkina Faso), where his fame attracts dozens of young musicians eager to learn from him. For Rido Bayonne is also a talent hunter and a teacher. The following year, he returns to conduct a six-week-long music workshop with 18 of these promising young performers who become the Jazz Orchestra of Burkina, «&nbsp;JOB&nbsp;». Their first concert is a great success. (The orchestra is still going strong).</p>
                    <p lang="en-US"  >In 2005, the Grand Orchestra travels to the Congo to participate in the «&nbsp;Fespam&nbsp;», the Panafrican Festival.&nbsp;In 2006, Rido Bayonne releases the «&nbsp;Hommage à la Femme&nbsp;» single, a tribute of 4 songs in honor of all women.&nbsp;He also conducts a workshop in the Congo and creates the «&nbsp;Kongo Groove System&nbsp;» band with «&nbsp;his kids&nbsp;».&nbsp;The following year, he’s back with the «&nbsp;Kongo Groove System&nbsp;» to open and close the Panafrican Festival. Always the forerunner, he invites polyphonic Pygmee singers to join his band for the grand finale and receives a standing ovation.</p>
                    <p lang="en-US"  >In 2007, he releases the «&nbsp;Douala-Brazza&nbsp;» album : 97 musicians performing a vibrant hommage to his two homelands, his native Congo and ‘adopted’ Cameroun.&nbsp;2007 is the year he celebrates his 60th birthday in Paris with a bang : 2 weeks of gala concerts with his Grand Orchestra, (now 60 performers strong), and no fewer than 200 guest musicians from all over, «&nbsp;his kids&nbsp;» as he likes to call them, wanting to join the party in honor of their «&nbsp;Tonton Rido&nbsp;» («&nbsp;Uncle Rido&nbsp;»).</p>
                    <p lang="en-US"  ><span lang="en-US">In 2008, </span><span lang="en-US"><i>«&nbsp;<strong>Rido Bayonne, Born in Africa&nbsp;»</strong>,</i></span><span lang="en-US"> the documentary film written and directed by<strong> Dom Pedro</strong> and produced by Grenade Productions </span><span lang="en-US">celebrates Rido Bayonne’s 30-year international career as outstanding member of the </span><span lang="en-US">very private club of giants of the world music scene. </span></p>
                    <p  ><span lang="en-US">Rido Bayonne is considered by many </span><span lang="en-US">of today’s top performers </span><span lang="en-US">as a ‘Master’, a reference, even an institution. Countless artists </span><span lang="en-US">have discovered or shaped their musical personality by working with him, notably<strong> Etienne M’Bappé, Paco Sery, Richard Bona, Assitan Dembélé, Michel Alibo, Jean-Pierre Como, Christian Martinez, Nicolas Guéret, Pierre Chabrèle, Christian Templet, Angeline Annonier, Bessy Gordon-Roy, Isabelle Gonzalez, Hervé Cavelier, Emile Parisien, Hadrien Feraud, Cédric Baud, Sista Clarisse</strong> and so many others …</span></p>
                    <p  ><span lang="en-US">Melodist, composer, arranger, performer and conductor in a class of his own, Rido Bayonne’s special gift is to craft </span><span lang="en-US">a truly original and authentic type of music, deeply rooted in tradition and enriched by cultural diversity – blending violins with drums, French with Lingala, English with Malagasy… : a celebration of </span><span lang="en-US">Life in all its shapes and forms, sparkling like fireworks &nbsp;!</span></p>
                    <p  ><span lang="en-US">&nbsp;By Claire Finlay</span></p>
                </div>
            </div>
            <div className="container-alliances"></div>
        </div>
    );
}


