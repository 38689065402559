import React from 'react';
import { Row, Col } from 'react-bootstrap';

import spotify from "../assets/logo/spotify.png"
import deezer from "../assets/logo/deezer-logo-coeur.png"
import apple from "../assets/logo/apple.png"
import amazon from "../assets/logo/amazon_music.png"
import youtube from "../assets/logo/ytb.png"

export const Music = () => {
    
  return (
    <div className="music-container">
        <h3 className='text-center music-titre'>Retrouvez Alliances sur toutes les plateformes</h3>
      <Row className="justify-content-center music-logo-container">
        <Col xs={4} md={2} className="center-content"> <a href='https://open.spotify.com/intl-fr/artist/75Xu6l5hFNMoVPPaVZ9ga1?si=Jw6ML6qCQqCjkKGDzytONA' target="_blank"><img src={spotify} className="logo-icon"/></a> </Col>
        <Col xs={4} md={2} className="center-content"> <a href='https://deezer.page.link/DKpSwpwD5dPXmMp67' target="_blank"> <img src={deezer} className="logo-icon"/></a> </Col>
        <Col xs={4} md={2} className="center-content"> <a href='https://music.apple.com/fr/artist/rido-bayonne/423286493' target="_blank"> <img src={apple} className="logo-icon"/> </a></Col>
        <Col xs={4} md={2} className="center-content"> <a href='https://music.amazon.com/artists/B004QLXGLY/rido-bayonne' target="_blank"><img src={amazon} className="logo-icon amazon-logo"/> </a></Col>
        <Col xs={4} md={2} className="center-content"> <a href='https://www.youtube.com/channel/UCmn4T5ejhSre6UZa1dFy1fw' target="_blank"><img src={youtube} className="logo-icon"/></a> </Col>
      </Row>
    </div>
  );
}
