import React from 'react';


import { Banner } from './Banner';
import { Music } from './Music';
import { Albums } from './Albums';
import { Evenements } from './Evenements';
import { Artists } from './Artists';
import { Alliances } from './Alliances';
import music from '../assets/music/RedMorning.mp3';


export const Home = () => {
  return (
    <>
      {/* <audio autoplay>
        <source src={music} type="audio/mpeg" />
      </audio> */}
      <Banner />
      <Music />
      <Albums />
      <Evenements />
      <Artists />
      <Alliances />

    </>
  );
}

