import React from 'react';
import Table from 'react-bootstrap/Table';



export const Evenements = () => {

    return (
        <div className="evenement-container">
            <h2 className='text-center'>Évenements à venir</h2>
            <h5>Pour plus d'informations concernant les événements de Rido Bayonne, veuillez lire, consulter les articles en page d'accueil, ou rendez-vous dans le menu événement pour voir une liste des événements en temps réel.</h5>


<Table className='table-style' striped  bordered>
      <thead>
        <tr>
          <th>Date</th>
          <th>Lieu</th>
          <th>Évenement</th>
          <th>Ticket</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td colSpan={4} className='centered-cell'>Pas d'événement pour le moment.</td>
        </tr>
      </tbody>
    </Table>

           
        </div>
    );
}


