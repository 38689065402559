import React from 'react';
import Table from 'react-bootstrap/Table';

export const Events = () => {

    return (
        <div>
            <div className="albums-all-container">
                <div className='banner-titre'>
                    <h1> ÉVENEMENT </h1>
                    <h2> Événement de Rido Bayonne</h2>
                </div>

<div className='events-container'>
                <Table className='table-style table-shadow' striped  bordered>
      <thead>
        <tr>
          <th>Date</th>
          <th>Lieu</th>
          <th>Évenement</th>
          <th>Ticket</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td colSpan={4} className='centered-cell'>Pas d'événement pour le moment.</td>
        </tr>
      </tbody>
    </Table>
    </div>
            </div>
            <div className="container-alliances"></div>
        </div>
    );
}


