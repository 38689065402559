import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../assets/logo/logo.jpg';

import logo2 from '../assets/logo/logo-rido3.png';

import spotify from "../assets/logo/spotify.png"
import deezer from "../assets/logo/deezer-logo-coeur.png"
import apple from "../assets/logo/apple.png"
import youtube from "../assets/logo/ytb.png"

import { Link } from 'react-router-dom';

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, seScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        seScrolled(true);
      } else {
        seScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);

  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
    setExpanded(false); // Close the navbar when a link is clicked
  }

  const navbarRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (expanded && navbarRef.current && !navbarRef.current.contains(e.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [expanded]);

  const handleToggleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Navbar ref={navbarRef} expand="lg" className={scrolled ? "scrolled fixed-top" : "fixed-top"} bg="dark" data-bs-theme="dark" expanded={expanded}>
      <Container style={{ zIndex: 999 }}>
        <Navbar.Brand as={Link} to="/" className='logo-navbar-brand'><img src={logo2} className="logo-rido" /></Navbar.Brand>
        <Navbar.Toggle onClick={handleToggleClick} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ zIndex: 999 }}>
        <Nav className="ml-auto logo-links">
        <Nav.Link as={Link} to="/" onClick={() => onUpdateActiveLink('home')}><img src={logo2} className="logo-rido logo-rido-nav" /></Nav.Link>
        </Nav>
          <Nav className="justify-content-center" style={{ zIndex: 999 }}>
            <Nav.Link as={Link} to="/" onClick={() => onUpdateActiveLink('home')}>ACCUEIL</Nav.Link>
            <Nav.Link as={Link} to="albums" onClick={() => onUpdateActiveLink('albums')}>ALBUMS</Nav.Link>
            <Nav.Link as={Link} to="biographie" onClick={() => onUpdateActiveLink('biographie')}>BIOGRAPHIE</Nav.Link>
            <NavDropdown
              title="NEWS"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="events" onClick={() => onUpdateActiveLink('events')}>ÉVENEMENTS</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="videos" onClick={() => onUpdateActiveLink('videos')}>VIDÉOS</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="presse" onClick={() => onUpdateActiveLink('presse')}>PRESSE</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="contact" onClick={() => onUpdateActiveLink('contact')}>CONTACT</Nav.Link>
          </Nav>
          <Nav className="ml-auto logo-links">
            <Nav.Link href="https://open.spotify.com/intl-fr/artist/75Xu6l5hFNMoVPPaVZ9ga1?si=Jw6ML6qCQqCjkKGDzytONA" target="_blank"><img src={spotify} className="logo-nb" /></Nav.Link>
            <Nav.Link href="https://music.apple.com/fr/artist/rido-bayonne/423286493" target="_blank"><img src={apple} className="logo-nb" /></Nav.Link>
            <Nav.Link href="https://deezer.page.link/DKpSwpwD5dPXmMp67" target="_blank"><img src={deezer} className="logo-nb" /></Nav.Link>
            <Nav.Link href="https://www.youtube.com/channel/UCmn4T5ejhSre6UZa1dFy1fw" target="_blank"><img src={youtube} className="logo-nb" /></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
