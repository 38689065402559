import React, { useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

export const Contact = () => {

  const form = useRef();

  const formInitialDetails = {
    name: '',
    mail: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Envoyer');
  const [status, setStatus] = useState({ success: null, message: '' });


  async function sendEmail(e) {
    e.preventDefault();
    setButtonText("...");
    let response = await fetch("https://mails.maissadev.com/rido_bayonne.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Envoyer");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.message == "OK") {
      setStatus({ success: true, message: 'Votre mail a bien été envoyé.' });
    } else {
      setStatus({ success: false, message: 'Une erreur s\'est produite.' });
    }
  }

  const onFormUpdate = (field, value) => {
    setFormDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

    return (
        <div>
            <div className="albums-all-container">
                <div className='banner-titre'>
                    <h1> CONTACT </h1>
                </div>

                <div className='contact-container'>
      
      <div className='single-container'>
       
       <Row>

       <Col xs={12} md={6} className='info-contact-col order-md-1'>
           <div className='info-contact-container'> 
          <h5> Contact </h5> 

        <p>Email : ridobayonne@free.fr</p>
           </div>
         </Col>

       <Col xs={12} md={6} className='order-md-2'>
           <div className='form-container'>
             <form ref={form} onSubmit={sendEmail}>
    
               
               <div className="mb-3">
                 <input
                   type="text"
                   name="name"
                   value={formDetails.name}
                   onChange={(e) => onFormUpdate('name', e.target.value)}
                   className="form-control input-form"
                   placeholder="Nom"
                   required
                 />
               </div>

               <div className="mb-3">
                 <input
                   type="email"
                   name="mail"
                   value={formDetails.email}
                   onChange={(e) => onFormUpdate('mail', e.target.value)}
                   className="form-control input-form"
                   placeholder="Email"
                   required
                 />
               </div>

               <div className="mb-3">
                 <textarea
                   name="message"
                   value={formDetails.message}
                   onChange={(e) => onFormUpdate('message', e.target.value)}
                   className="form-control text-form"
                   rows="4"
                   placeholder="Message"
                   required
                 />
               </div>

               <div className="d-flex justify-content-end">
                 <button type="submit" className="input-rectangle">
                   {buttonText}
                 </button>
                 {/* Envoyer a ridobayonne@free.fr */}
               </div>

              </form>
              {status.success !== null && (
                  <div className={`status-message ${status.success ? 'success' : 'error'}`}>
                    {status.message}
                  </div>
                )}
           </div>
         </Col>

     </Row>
   
        </div>
      </div>
        
            </div>
            <div className="container-alliances"></div>
        </div>
    );
}


