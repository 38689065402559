import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import journal1 from  '../../assets/img/presse1.jpg';
import journal2 from  '../../assets/img/presse2.jpg';

export const Presse = () => {

    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
        document.body.classList.add('no-scroll'); // Ajoute une classe au corps pour désactiver le défilement
    };

    const handleCloseModal = () => {
        setShowImageModal(false);
        setSelectedImage(null);
        document.body.classList.remove('no-scroll'); // Supprime la classe pour réactiver le défilement
    };



    return (
        <>
            <div className='banner-titre'>
                <h1> PRESSE </h1>
            </div>
            <div className='presse-container'>
                <Row>
                        <Col xs={12} md={6} lg={3}>
                            <img src={journal1} onClick={() => handleImageClick(journal1)}/>
                        </Col>

                        <Col xs={12} md={6} lg={3}>
                            <img src={journal2} onClick={() => handleImageClick(journal2)}/>
                        </Col>
                </Row>
            </div>
            {showImageModal && (
                <div className="image-modal-container" onClick={handleCloseModal}>
                     <img src={selectedImage} className="image-modal-news"/>
                    {/* <div className="image-modal-content">
                       
                    </div> */}
                </div>
            )}
        </>
    );
}
