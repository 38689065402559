import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import img from '../../assets/albums/album3.jpg'

import spotify from "../../assets/logo/spotify.png"
import deezer from "../../assets/logo/deezer-logo-coeur.png"
import apple from "../../assets/logo/apple.png"
import amazon from "../../assets/logo/amazon_music.png"
import youtube from "../../assets/logo/ytb.png"

export const HommageAlbum = () => {
  const iframeCode = `
  <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/7EXJUAvXjjx4V1QJxogS1l?utm_source=generator" width="70%" height="400px" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  `;

  return (
    <>
      <div className='music-info-container '>

        <Row >
          <Col sm={12} md={12} lg={4} className='align-items-center'>
            <img src={img} className="alliancesimg" />
          </Col>
          <Col sm={12} md={12} lg={4} className='text-left info-alliances'>
            <h2 className='music-titre'>Hommage à la femme</h2>
            <p className='home-about-p start-music album-info'>
              Date de sortie : March 11, 2014 <br/>
              Producteur : Rido Bayonne
            </p>
            <div className="button-container">
              <div className="bouton-rectangle-container-alliances">
                <a href='https://www.amazon.fr/HOMMAGE-FEMME-RIDO-BAYONNE/dp/B0058A60JW/ref=sr_1_1?ie=UTF8&qid=1521630949&sr=8-1&keywords=rido+bayonne' target="_blank" className="bouton-rectangle-alliances-d">
                  ACHETER
                </a>
              </div>
            </div>
          </Col>
          {/* <Col sm={12} md={12} lg={4} className='text-center alliances-un'>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCode }}
            />
          </Col> */}
        </Row>

      </div>

      <div className="music-container" style={{ paddingBottom: '0px' }}>

<h3 className='text-center music-titre'>Disponible sur toutes les plateformes musicales</h3>
<Row className="justify-content-center all-music-container">
  <Col xs={4} md={2} className="center-content"> <a href='https://open.spotify.com/intl-fr/album/7EXJUAvXjjx4V1QJxogS1l?si=3tfy0R9AS7aW6SnBPvmqrw' target="_blank"><img src={spotify} className="logo-icon" /></a> </Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://deezer.page.link/PaBkDtU91fSUzzFV9' target="_blank"> <img src={deezer} className="logo-icon" /></a> </Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://music.apple.com/fr/album/hommage-%C3%A0-la-femme-ep/1738193436' target="_blank"> <img src={apple} className="logo-icon" /> </a></Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://music.amazon.com/albums/B0CZ6Z1F7V?marketplaceId=A13V1IB3VIYZZH&musicTerritory=FR&ref=dm_sh_2dc3t8tKIBzr9Vgj4XQx4ZWqQ' target="_blank"><img src={amazon} className="logo-icon amazon-logo" /> </a></Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://www.youtube.com/watch?v=ixjpQlu1b0w&list=OLAK5uy_kcWgxojQSLl_X5GZy5mgDmFY8kuAuxAes' target="_blank"><img src={youtube} className="logo-icon" /></a> </Col>
</Row>
</div>

      <div className='music-spotify' style={{ paddingBottom: '30px' }}>
        <Row>
          <Col xs={12} className='text-center'>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCode }}
            />
          </Col>

        </Row>
      </div>



    </>
  );
}

