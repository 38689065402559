import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import spotify from "../../assets/logo/spotify.png"
import deezer from "../../assets/logo/deezer-logo-coeur.png"
import apple from "../../assets/logo/apple.png"
import amazon from "../../assets/logo/amazon_music.png"
import youtube from "../../assets/logo/ytb.png"

import alliances from '../../assets/img/alliances.jpg';

import Artists from '../Artists';
import Livret from '../Livret';


export const AlliancesAlbum = () => {
  const [loading, setLoading] = useState(true); 
  const iframeCode = `
  <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/1NRJ9vJTOZ0e7HnMA3SOsM?utm_source=generator" width="100%" height="500" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  `;

  const iframeCodes = `
  <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/224FMZQz8wLB4CyESsuwSB?utm_source=generator" width="100%" height="500" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  `;





  return (
    <>


      <div className='music-info-container'>

        <Row >
          <Col sm={12} md={12} lg={4} className='col-1'>
            <img src={alliances} className="alliancesimg" />
          </Col>
          <Col sm={12} md={12} lg={6} className='text-left info-alliances'>
            <h2 className='music-titre'>Alliances</h2>
            <p className='home-about-p start-music album-info'>
              Découvrez le dernier album de Rido Bayonne, intitulé 'Alliances'. Ce double album compte 22 chansons, réparties en 11 titres par album. Plongez dans l'univers musical captivant de Rido Bayonne, où chaque chanson est une pièce unique vous guidant à travers un voyage sonore profondément émotionnel.
            </p>
            <div className="button-container">
              <div className="bouton-rectangle-container-alliances">
              <a href='https://buy.ridobayonne.fr/' target="_blank" className="bouton-rectangle-alliances-d">
                  ACHETER
                </a>
                <a href="#artists" className="bouton-rectangle-alliances">
                  Voir les artistes
                </a>
                <a href="#livret" className="bouton-rectangle-alliances">
                  Voir le livret
                </a>
               
              </div>
            </div>
          </Col>

        </Row>

      </div>

      <div className='music-spotify'>
        <Row>
          <Col xs={12} md={6} className='text-center alliances-un'>
            <h5 className='music-titre'>Alliances cd1</h5>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCode }}
            />
          </Col>
          <Col xs={12} md={6} className='text-center'>
            <h5 className='music-titre'>Alliances cd2</h5>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCodes }}
            />
          </Col>

        </Row>
      </div>

      <div className="music-container">

        <h3 className='text-center music-titre'>Disponible sur toutes les plateformes musicales</h3>
        <Row className="justify-content-center all-music-container">
          <Col xs={4} md={2} className="center-content"> <a href='https://open.spotify.com/intl-fr/album/224FMZQz8wLB4CyESsuwSB?si=E6oi4u5bS2GxYEZjlA0pUA' target="_blank"><img src={spotify} className="logo-icon" /></a> </Col>
          <Col xs={4} md={2} className="center-content"> <a href='https://deezer.page.link/SmD9Eg19z1niGJ3T8' target="_blank"> <img src={deezer} className="logo-icon" /></a> </Col>
          <Col xs={4} md={2} className="center-content"> <a href='https://music.apple.com/fr/album/alliances/1708546074' target="_blank"> <img src={apple} className="logo-icon" /> </a></Col>
          <Col xs={4} md={2} className="center-content"> <a href='https://music.amazon.com/albums/B0CJLKW52Q?marketplaceId=A13V1IB3VIYZZH&musicTerritory=FR&ref=dm_sh_tjioYDB0VXy3mPZJy8EosqUFX' target="_blank"><img src={amazon} className="logo-icon amazon-logo" /> </a></Col>
          <Col xs={4} md={2} className="center-content"> <a href='https://youtu.be/YH3igpl4P-4?si=fAKqCvmKqrRgoWDg' target="_blank"><img src={youtube} className="logo-icon" /></a> </Col>
        </Row>
      </div>

      <div id="artists">
        <Artists />
      </div>

      <div id="livret">
        <Livret />
      </div>


      <div className="container-alliances"></div>
    </>
  );
}

