import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';



import img1 from "../assets/img/alliances.jpg";
import img2 from "../assets/img/profil_rido.jpg";
import img3 from "../assets/img/concert.jpg";

import alliances from "../assets/img/alliances.jpg"

export const Banner = () => {
  const images = [img1, img2, img3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
    <div
      className="back-img"
      style={{
        backgroundImage: `url(${images[0]})`,
      }}
    >
      <div className='over-banner center-content'>
        {/* Contenu de l'overlay (texte et boutons) */}
        <div className='info-banner'>


            <div>
            <Link to='/album/alliances'>
            <img src={alliances} alt="Image" className='alliances-img-banner'/>
            </Link>
            </div>

            <div className='banner-2'>

          <h1>"Alliances" -  Nouvel Album</h1>
          <h2>Rido Bayonne</h2>

         


        
        <div className="button-container">
          <div className="bouton-rectangle-container bouton-rectangle-container-banner">
            <Link to={'/album/alliances'} className="bouton-rectangle bouton-rectangle-banner">
              ALBUM ALLIANCES
            </Link>
            <Link to={'/albums'} className="bouton-rectangle bouton-rectangle-banner">
              DISCOGRAPHIE
              </Link>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>

    <div>

                <div className='banner-1'>
        <h2> "Au commencement, il y eut le silence,  du silence émergea la musique, <br/>enfin, de la musique vint la vie" </h2> 
            <h5>François BERNAUD </h5>
            </div>
    </div>
    </>
  );
  
};
