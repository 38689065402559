import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';

import { Home } from './components/Home';
import { AlbumsAll } from './components/onglets/AlbumsAll';
import { Events } from './components/onglets/Events';
import { Video } from './components/onglets/Video';
import { Presse } from './components/onglets/Presse';
import { Bio } from './components/onglets/Bio';
import { BioEn } from './components/onglets/BioEn';
import { Contact } from './components/onglets/Contact';
import { AlliancesAlbum } from './components/onglets/AlliancesAlbum';
import { HommageAlbum} from './components/onglets/HommageAlbum';
import { DoualaAlbum} from './components/onglets/DoualaAlbum';
import { CoeursAlbum } from './components/onglets/CoeursAlbum';
import { EyanoAlbum} from './components/onglets/EyanoAlbum';
import { GueuleAlbum} from './components/onglets/GueuleAlbum';
import { RidoAlbum} from './components/onglets/RidoAlbum';

import ScrollToTop from './components/ScrollToTop';
import { NotFound } from './components/NotFound';

function App() {
  return (
    <>
      <Router>
        <NavBar/>
        <ScrollToTop />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/albums" element={<AlbumsAll />} />
        <Route path="/events" element={<Events/>} />
        <Route path="/videos" element={<Video/>} />
        <Route path="/presse" element={<Presse/>} />
        <Route path="/biographie" element={<Bio/>} />
        <Route path="/biographie-english" element={<BioEn/>} />
        <Route path="/contact" element={<Contact/>} />

        <Route path="/album/alliances" element={<AlliancesAlbum/>} />
        <Route path="/album/hommage-a-la-femme" element={<HommageAlbum/>} />
        <Route path="/album/douala-brazza" element={<DoualaAlbum/>} />
        <Route path="/album/a-coeurs-et-ames" element={<CoeursAlbum/>} />
        <Route path="/album/gueule-de-black" element={<GueuleAlbum/>} />
        <Route path="/album/eyano" element={<EyanoAlbum/>} />
        <Route path="/album/rido-et-james" element={<RidoAlbum/>} />
        <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
