import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const Video = () => {
  const concertdebut = '<iframe width="560" height="315" src="https://www.youtube.com/embed/FcwDTVXT_hI?si=GrYyPRYfIPFMP-WM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
  const concertfin = '<iframe width="560" height="315" src="https://www.youtube.com/embed/TWffNJiSTxk?si=hm-21hnCfVx6uDPT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';

  return (
    <div>
      <div className="albums-all-container">
        <div className='banner-titre'>
          <h1> VIDÉOS </h1>
        </div>
      </div>
      <div className='video-container'>
        <Row>
          <Col md={12} lg={6} className="d-flex justify-content-center">
            <div dangerouslySetInnerHTML={{ __html: concertdebut }} />
          </Col>
          <Col md={12} lg={6} className="d-flex justify-content-center">
            <div dangerouslySetInnerHTML={{ __html: concertfin }} />
          </Col>
        </Row>
      </div>
      <div className="container-alliances"></div>
    </div>
  );
}
