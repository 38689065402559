import React from 'react';
import { Link } from 'react-router-dom';
import alliances from "../assets/img/alliances.jpg"


export const Alliances = () => {

  return (

    <div className="container-alliances">
    <Link to='/album/alliances'>
      <div className="imgContainerAlliances">
        <img src={alliances} alt="Image" className='alliances-img'/>
        <div className="overlayAlliances">
          {/* Contenu de votre overlay */}
        </div>
      </div>
    </Link>
  </div>
  
   



  );
}


