import React from 'react';

export const NotFound = () => {
  return (
    <div className='not-found-page'>
      <h1>Oups ! Page introuvable</h1>
      <p>Désolé, la page que vous recherchez n'existe pas.</p>
    </div>
  );
}
