import React from 'react';

import angeline from "../assets/artistes/angeline_annonier-270x270.jpg";
import armel from "../assets/artistes/armel_malonga-270x270.jpg";
import cedric from "../assets/artistes/cedric-baud-270x270.jpg";
import emile from "../assets/artistes/emile-parisien-270x270.jpg";
import etienne from "../assets/artistes/etienne_mbappe-270x270.jpg";
import fafa from "../assets/artistes/fafa_rufino-270x270.jpg";
import gasandji from "../assets/artistes/gasandji-270x270.jpg";
import gerard from "../assets/artistes/gerard-carocci-270x270.jpg";
import gregory from "../assets/artistes/gregory.jpg";
import jackson from "../assets/artistes/Jackson_babingui-270x270.jpg";
import jean from "../assets/artistes/Jean-pierre-como-270x270.jpg";
import pierre from "../assets/artistes/Jean-.jpg";
import jane from "../assets/artistes/jeanette-kuehn-270x270.jpg";
import jiji from "../assets/artistes/Jiji-270x270.jpg";
import louis from "../assets/artistes/Louis-winsberg-270x270.jpg";
import malik from "../assets/artistes/malik-mezzadri-270x270.jpg";
import oliver from "../assets/artistes/Oliver_tchimanga-270x270.jpg";
import paco from "../assets/artistes/paco-sery-270x270.jpg";
import taneau from "../assets/artistes/taneau-mustafa-assemian-270x270.jpg";
import vincent from "../assets/artistes/Vincent.jpg";


export const Artists = () => {
    const artistsData = [
        {
          id: 1,
          name: 'Angéline Annonier',
          image: angeline,
          website: 'http://www.angeline.biz',
        },
        {
          id: 2,
          name: 'Armel Malonga',
          image: armel,
          website: null,
        },
        {
          id: 3,
          name: 'Cedric Baud',
          image: cedric,
          website: null,
        },
        {
          id: 4,
          name: 'Emile Parisien',
          image: emile,
          website: 'https://emileparisien.fr',
        },
        {
          id: 5,
          name: 'Etienne M’Bappé',
          image: etienne,
          website: 'https://etiennembappe.com',
        },
        {
          id: 6,
          name: 'Fafa Rufino',
          image: fafa,
          website: null,
        },
        {
            id: 7,
            name: 'Gerard Carocci',
            image: gerard,
            website: null,
          },
          {
            id: 8,
            name: 'Gasandji',
            image: gasandji,
            website: null,
          },
          {
            id: 9,
            name: 'Gregory Privat',
            image: gregory,
            website: 'https://www.gregoryprivat.com',
          },
          {
            id: 10,
            name: 'Jeanette Kuehn',
            image: jane,
            website: 'http://www.lumiereadonner.com',
          },
          {
            id: 11,
            name: 'Jiji',
            image: jiji,
            website: null,
          },
          {
            id: 12,
            name: 'Jackson Babingui',
            image: jackson,
            website: null,
          },
          {
            id: 13,
            name: 'Jean Pierre Solves',
            image: jean,
            website: 'http://jeanpierresolves.weebly.com',
          },
          {
            id: 14,
            name: 'Jean-Pierre Como',
            image: pierre,
            website: null,
          },
          {
            id: 15,
            name: 'Louis Winsberg',
            image: louis,
            website: 'https://louis-winsberg.com',
          },
          {
          id: 16,
          name: 'Malik-Mezzadri',
          image: malik,
          website: null,
        },
        {
          id: 17,
          name: 'Olivier Tshimanga',
          image: oliver,
          website: null,
        },
        {
          id: 18,
          name: 'Paco Sery',
          image: paco,
          website: 'https://www.pacosery.com',
        },
        {
          id: 19,
          name: 'Tanoh-Mustafa-Assemian',
          image: taneau,
          website: null,
        },
        {
          id: 20,
          name: 'Vincent Ségal',
          image: vincent,
          website: null,
        }
      ];
  return (
    <div className='artists'>
    <h2 className='artists-titre'>Quelques artistes de l'album Alliances</h2>
    <div className="container-artists">
        {artistsData.map((artist, index) => (
             <a href={artist.website} target="_blank" rel="noopener noreferrer">
        
        <div key={artist.id} className={`artist-card ${index % 4 === 3 ? 'last-in-row' : ''}`}>
            <img src={artist.image} alt={artist.name} className="artist-image" />
           
                <p className="artist-name">{artist.name}
            </p>
          
        </div>
        </a>
      ))}
    </div>
            </div>
  );
};

export default Artists;



