import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import album1 from "../../assets/albums/album1.jpg";
import album2 from "../../assets/albums/album2.jpg";
import album3 from "../../assets/albums/album3.jpg";
import album4 from "../../assets/albums/acea.jpg";
import album5 from "../../assets/albums/album5.jpg";
import album6 from "../../assets/albums/album6.jpg";
import album7 from "../../assets/albums/album7.jpg";


export const AlbumsAll = () => {

    return (
        <div>
            <div className="albums-all-container">
                <div className='banner-titre'>
                    <h1> ALBUMS </h1>
                    <h2> Avant l'homme et l'amour.. La musique et la fleur... Tant que le cœur des hommes battra, il rythmera la musique permettant à tous d'avancer d'un pas cadencé vers une communion universelle, emprunte d’émotion et de parfum.</h2>
                </div>

                <h2 className='text-center albums-titre music-titre'>Albums</h2>

                <Row className="align-items-center albums-all">
                    <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
                        <Link to='/album/alliances'>
                            <div className="imgContainer">
                                <img src={album1} alt="Image" />
                                <div className="overlay">
                                    <p className="text-overlay">
                                        {" "}
                                        <span className="title-overlay"> Alliances </span>{" "}
                                        <br />{" "}
                                        <span className="desc-overlay"> April, 2018 </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
                    <Link to='/album/hommage-a-la-femme'>
                            <div className="imgContainer">
                                <img src={album3} alt="Image" />
                                <div className="overlay">
                                    <p className="text-overlay">
                                        {" "}
                                        <span className="title-overlay"> Hommage à la femme </span>{" "}
                                        <br />{" "}
                                        <span className="desc-overlay"> March 11, 2014 </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
                    <Link to='/album/douala-brazza'>
                            <div className="imgContainer">
                                <img src={album2} alt="Image" />
                                <div className="overlay">
                                    <p className="text-overlay">
                                        {" "}
                                        <span className="title-overlay"> Douala-Brazza </span>{" "}
                                        <br />{" "}
                                        <span className="desc-overlay"> April, 2006 </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
                    <Link to='/album/a-coeurs-et-ames'>
                            <div className="imgContainer">
                                <img src={album4} alt="Image" />
                                <div className="overlay">
                                    <p className="text-overlay">
                                        {" "}
                                        <span className="title-overlay"> À coeurs et âmes </span>{" "}
                                        <br />{" "}
                                        <span className="desc-overlay"> Album sorti en 2001, dans lequel Rido Bayonne à réuni plus de 150 musiciens. </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>

                <Row className="align-items-center albums-all">

                    <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
                        <Link to='/album/gueule-de-black'>
                            <div className="imgContainer">
                                <img src={album5} alt="Image" />
                                <div className="overlay">
                                    <p className="text-overlay">
                                        {" "}
                                        <span className="title-overlay"> Gueule de Black </span>{" "}
                                        <br />{" "}
                                        <span className="desc-overlay"> Album sorti en 1996, enregistré en live à Bayonne lors des Fêtes de Bayonne </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
                    <Link to='/album/eyano'>
                            <div className="imgContainer">
                                <img src={album6} alt="Image" />
                                <div className="overlay">
                                    <p className="text-overlay">
                                        {" "}
                                        <span className="title-overlay"> Eyano </span>{" "}
                                        <br />{" "}
                                        <span className="desc-overlay"> "Eyano" premier album sorti en 1982. </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="d-flex justify-content-center">
                    <Link to='/album/rido-et-james'>
                            <div className="imgContainer">
                                <img src={album7} alt="Image" />
                                <div className="overlay">
                                    <p className="text-overlay">
                                        {" "}
                                        <span className="title-overlay"> Rido & James </span>{" "}
                                        <br />{" "}
                                        <span className="desc-overlay"> 45 tours enregistré en 1979. </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>


            </div>
            <div className="container-alliances"></div>
        </div>
    );
}


