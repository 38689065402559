import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import img from '../../assets/albums/album5.jpg'

import spotify from "../../assets/logo/spotify.png"
import deezer from "../../assets/logo/deezer-logo-coeur.png"
import apple from "../../assets/logo/apple.png"
import amazon from "../../assets/logo/amazon_music.png"
import youtube from "../../assets/logo/ytb.png"

export const GueuleAlbum = () => {

  const iframeCode = `
  <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/3Qynf7Z4wsvyZa7dz6dt6R?utm_source=generator" width="70%" height="400" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  `;
  return (
    <>
      <div className='music-info-container'>

        <Row >
          <Col sm={12} md={12} lg={4} className='align-items-center'>
            <img src={img} className="alliancesimg" />
          </Col>
          <Col sm={12} md={12} lg={6} className='text-left info-alliances'>
            <h2 className='music-titre'>Gueule de black</h2>
            <p className='home-about-p start-music album-info'>
              Date de sortie : album sorti en 1996. Enregistré en live à Bayonne lors des Fêtes de Bayonne <br/>
              Producteur : Rido Bayonne
            </p>
            <div className="button-container">
              <div className="bouton-rectangle-container-alliances">
                {/* <a href='#' className="bouton-rectangle-alliances-d" disabled>
                  ACHETER
                </a> */}
              </div>
            </div>
          </Col>

        </Row>

      </div>

      <div className="music-container" style={{ paddingBottom: '0px' }}>

<h3 className='text-center music-titre'>Disponible sur toutes les plateformes musicales</h3>
<Row className="justify-content-center all-music-container">
  <Col xs={4} md={2} className="center-content"> <a href='https://open.spotify.com/intl-fr/album/3Qynf7Z4wsvyZa7dz6dt6R?si=yEfgh3OER46x0V142eHTGQ' target="_blank"><img src={spotify} className="logo-icon" /></a> </Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://deezer.page.link/pago72vyRqzzSiDX9' target="_blank"> <img src={deezer} className="logo-icon" /></a> </Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://music.apple.com/fr/album/gueule-de-black/1719259329' target="_blank"> <img src={apple} className="logo-icon" /> </a></Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://music.amazon.com/albums/B0CP9J2GZJ?marketplaceId=A13V1IB3VIYZZH&musicTerritory=FR&ref=dm_sh_xcz89zaMBDZRnRFUWFsH53JMJ' target="_blank"><img src={amazon} className="logo-icon amazon-logo" /> </a></Col>
  <Col xs={4} md={2} className="center-content"> <a href='https://www.youtube.com/watch?v=pjW1St9bs7c&list=OLAK5uy_m2NSjK5Iws1XrAXBL9Kq81ekvcWrjhjcY' target="_blank"><img src={youtube} className="logo-icon" /></a> </Col>
</Row>
</div>

      <div className='music-spotify' style={{ paddingBottom: '30px' }}>
        <Row>
          <Col xs={12} className='text-center'>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCode }}
            />
          </Col>

        </Row>
      </div>

    </>
  );
}

