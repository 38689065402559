import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import img from '../../assets/albums/album6.jpg'

export const EyanoAlbum = () => {
  return (
    <>
      <div className='music-info-container other-albums'>

        <Row >
          <Col sm={12} md={12} lg={4} className='align-items-center'>
            <img src={img} className="alliancesimg" />
          </Col>
          <Col sm={12} md={12} lg={6} className='text-left info-alliances'>
            <h2 className='music-titre'>Eyano</h2>
            <p className='home-about-p start-music album-info'>
              Date de sortie : "Eyano" premier album sorti en 1982. <br/>
              Producteur : Rido Bayonne
            </p>
            <div className="button-container">
              <div className="bouton-rectangle-container-alliances">
                {/* <a href="#" className="bouton-rectangle-alliances-d" disabled>
                  ACHETER
                </a> */}
              </div>
            </div>
          </Col>

        </Row>

      </div>

    </>
  );
}

