import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import img1 from '../assets/livret/LIVRET1.jpg';
import img2 from '../assets/livret/LIVRET2.jpg';
import img3 from '../assets/livret/LIVRET3.jpg';
import img4 from '../assets/livret/LIVRET4.jpg';
import img5 from '../assets/livret/LIVRET5.jpg';
import img6 from '../assets/livret/LIVRET6.jpg';
import img7 from '../assets/livret/LIVRET7.jpg';
import img8 from '../assets/livret/LIVRET8.jpg';
import img9 from '../assets/livret/LIVRET9.jpg';
import img10 from '../assets/livret/LIVRET10.jpg';
import img11 from '../assets/livret/LIVRET11.jpg';
import img12 from '../assets/livret/LIVRET12.jpg';
import img13 from '../assets/livret/LIVRET13.jpg';
import img14 from '../assets/livret/LIVRET14.jpg';
import img15 from '../assets/livret/LIVRET15.jpg';
import img16 from '../assets/livret/LIVRET16.jpg';
import img17 from '../assets/livret/LIVRET17.jpg';
import img18 from '../assets/livret/LIVRET18.jpg';
import img19 from '../assets/livret/LIVRET19.jpg';
import img20 from '../assets/livret/LIVRET20.jpg';
import img21 from '../assets/livret/LIVRET21.jpg';
import img22 from '../assets/livret/LIVRET22.jpg';
import img23 from '../assets/livret/LIVRET23.jpg';
import img24 from '../assets/livret/LIVRET24.jpg';
import img25 from '../assets/livret/LIVRET25.jpg';
import img26 from '../assets/livret/LIVRET26.jpg';
import img27 from '../assets/livret/LIVRET27.jpg';
import img28 from '../assets/livret/LIVRET28.jpg';
import img29 from '../assets/livret/LIVRET29.jpg';
import img30 from '../assets/livret/LIVRET30.jpg';
import img31 from '../assets/livret/LIVRET31.jpg';


export const Livret = () => {

    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
        document.body.classList.add('no-scroll'); // Ajoute une classe au corps pour désactiver le défilement
    };

    const handleCloseModal = () => {
        setShowImageModal(false);
        setSelectedImage(null);
        document.body.classList.remove('no-scroll'); // Supprime la classe pour réactiver le défilement
    };


    return (
            <div className='livret-container'>
            <h2 className='artists-titre' style={{ paddingBottom: '30px' }}>Le livret de l'album Alliances</h2>
                <div >

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img1} onClick={() => handleImageClick(img1)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img2} onClick={() => handleImageClick(img2)} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img3} onClick={() => handleImageClick(img3)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img4} onClick={() => handleImageClick(img4)} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img5} onClick={() => handleImageClick(img5)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img6} onClick={() => handleImageClick(img6)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img7} onClick={() => handleImageClick(img7)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img8} onClick={() => handleImageClick(img8)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img9} onClick={() => handleImageClick(img9)} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img10} onClick={() => handleImageClick(img10)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img11} onClick={() => handleImageClick(img11)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img12} onClick={() => handleImageClick(img12)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img13} onClick={() => handleImageClick(img13)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img14} onClick={() => handleImageClick(img14)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img15} onClick={() => handleImageClick(img15)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img16} onClick={() => handleImageClick(img16)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img17} onClick={() => handleImageClick(img17)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img18} onClick={() => handleImageClick(img18)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img19} onClick={() => handleImageClick(img19)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img20} onClick={() => handleImageClick(img20)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img21} onClick={() => handleImageClick(img21)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img22} onClick={() => handleImageClick(img22)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img23} onClick={() => handleImageClick(img23)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img24} onClick={() => handleImageClick(img24)} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img25} onClick={() => handleImageClick(img25)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img26} onClick={() => handleImageClick(img26)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img27} onClick={() => handleImageClick(img27)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img28} onClick={() => handleImageClick(img28)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img29} onClick={() => handleImageClick(img29)}/>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img30} onClick={() => handleImageClick(img30)}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4}  className="d-flex align-items-center justify-content-center">
                        <img src={img31} onClick={() => handleImageClick(img31)}/>
                        </Col>
                    </Row>

                    
                </div>
                {showImageModal && (
                <div className="image-modal-container" onClick={handleCloseModal}>
                     <img src={selectedImage} className="image-modal"/>
                    {/* <div className="image-modal-content">
                       
                    </div> */}
                </div>
            )}
            </div>
            );
}

export default Livret;


