import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
      {/* Votre contenu */}
    </div>
  );
}

export default ScrollToTop;
